import React, { useEffect, useState } from "react";
import Prism from "prismjs";
import {
  Card,
  Container,
  Nav,
  Modal,
  Form,
  Button,
  Tab,
  Col,
  Row,
  Spinner,
} from "react-bootstrap";

import Swal from "sweetalert2";
import { showLoadingModal, hideLoadingModal } from "../../logic/Utils";
import * as XLSX from 'xlsx';

import axios from "axios";

import { Link } from "react-router-dom";
import Sidebar from "../../layouts/Sidebar";
import Header from "../../layouts/Header";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";


const EnrolleeActivityGrid = (props) => {
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [viewMode, setViewMode] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const [rows, setRows] = useState(props.data);
  
    const handleView = (row) => {
      setShowModal(true);
      setViewMode(true);
      setSelectedActivity(row);
    };
  
    const handleClose = () => {
      setShowModal(false);
      setSelectedActivity(null);
    };
  
    const activityColumns = [
      {
        field: "enrolleeId",
        headerName: "ID",
        flex: 1,
      },
      {
        field: "fullName",
        headerName: "Enrollee Name",
        flex: 2,
      },
      {
        field: "requestSource",
        headerName: "Request Source",
        flex: 2,
      },
      {
        field: "firstWaitingTime",
        headerName: "T1",
        flex: 1,
        renderCell: (params) => (
          <span
            style={{
              backgroundColor:
                params.value > 60 ? "red" : params.value > 30 ? "orange" : "lightgreen",
              color: "white",
              padding: '8px',
              borderRadius: '4px'
            }}
          >
            {params.value}
          </span>
        ),
      },
      {
        field: "secondWaitingTime",
        headerName: "T2",
        flex: 1,
        renderCell: (params) => (
          <span
            style={{
              backgroundColor:
                params.value > 10 ? "red" : params.value > 5 ? "orange" : "lightgreen",
              color: "white",
              padding: '8px',
              borderRadius: '4px'
            }}
          >
            {params.value}
          </span>
        ),
      },
      {
        field: "totalWaitingTime",
        headerName: "Total WT",
        flex: 1,
        renderCell: (params) => (
          <span
            style={{
              backgroundColor: params.value > 75 ? "red" : "lightgreen",
              color: "white",
              padding: '8px',
              borderRadius: '4px'
            }}
          >
            {params.value}
          </span>
        ),
        
      },
      {
        field: "datePresentString",
        headerName: "Date Present",
        flex: 2,
      },
      {
        field: "dateOfPaRequestString",
        headerName: "Date of PA Request",
        flex: 2,
      },
      {
        field: "dateOfPaIssuanceString",
        headerName: "Date of PA Issuance",
        flex: 2,
      },
      {
        field: "provider",
        headerName: "Provider",
        flex: 3,
      },
      {
        field: "remarks",
        headerName: "Remarks",
        flex: 3,
      },
    ];
    

  
    return (
      <React.Fragment>
<div style={{ height: 600, width: "100%", position: 'relative' }}>
  <DataGrid
    rows={rows}
    slots={{ toolbar: GridToolbar }}
    columns={activityColumns}
    pageSize={20}
    getRowHeight={() => 'auto'}
    rowSelectionModel={rowSelectionModel}
    sx={{
      '& .MuiDataGrid-columnHeaders': {
        backgroundColor: 'rgba(120,144,156,0.5)', // Custom header background color
        fontSize: '1rem', // Custom header font size
        whiteSpace: 'normal', // Enable word wrap in headers
        wordWrap: 'break-word', // Ensure long words are broken in headers
        lineHeight: '1.5', // Adjust line height for better readability
        display: 'flex',
        padding: '10px',
        alignItems: 'center',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        whiteSpace: 'normal', // Enable word wrap in headers
        overflowWrap: 'break-word', // Break words if they are too long
        lineHeight: '1.5', // Adjust line height for better readability
      },
      '& .MuiDataGrid-columnHeader--frozen-right': {
        position: 'sticky',
        right: 0, // Stick the header to the right
        zIndex: 2, // Ensures the frozen header stays above the rest of the headers
        backgroundColor: 'rgba(120,144,156,0.5)', // Same background color as header for consistency
      },
      '& .MuiDataGrid-cell': {
        whiteSpace: 'normal', // Allow text wrapping
        wordWrap: 'break-word', // Break words if necessary
        lineHeight: '1.5', // Adjust line height for better readability
        alignItems: 'center', // Center the content vertically
        display: 'flex',
        padding: '10px',
      },
      '& .MuiDataGrid-cell--frozen-right': {
        position: 'sticky',
        right: 0, // Stick the cell to the right
        backgroundColor: '#f4f6f8', // Background color for the frozen cell
        zIndex: 1, // Ensures the frozen cell stays on top of other cells
      },
    }}
    getCellClassName={(params) => {
      if (params.field === 'remarks') {
        return 'MuiDataGrid-cell--frozen-right'; // Apply frozen effect to Enrollee ID cells on the right
      }
      return '';
    }}
    getColumnHeaderClassName={(params) => {
      if (params.field === 'remarks') {
        return 'MuiDataGrid-columnHeader--frozen-right'; // Apply frozen effect to Enrollee ID header on the right
      }
      return '';
    }}
  />
</div>


  

  
        {selectedActivity && viewMode && (
          <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Enrollee Activity Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Label>Enrollee ID</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedActivity.enrolleeId}
                  disabled
                  style={{ marginBottom: "20px" }}
                />
  
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedActivity.lastName}
                  disabled
                  style={{ marginBottom: "20px" }}
                />
  
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedActivity.firstName}
                  disabled
                  style={{ marginBottom: "20px" }}
                />
  
                <Form.Label>Provider</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedActivity.provider}
                  disabled
                  style={{ marginBottom: "20px" }}
                />
  
                <Form.Label>Date Present</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedActivity.datePresent}
                  disabled
                  style={{ marginBottom: "20px" }}
                />
  
                <Form.Label>Remarks</Form.Label>
                <Form.Control
                  type="text"
                  value={selectedActivity.remarks}
                  disabled
                  style={{ marginBottom: "20px" }}
                />
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </React.Fragment>
    );
  };
  



export default function EnrolleeActivity() {
    const currentSkin = localStorage.getItem("skin-mode") ? "dark" : "";
    const [skin, setSkin] = useState(currentSkin);
    const [isLoading, setIsLoading] = useState(false);
    const [isProcessed, setIsProcessed] = useState(false);
    const [activity, setActivity] = useState([]);
    const [fileData, setFileData] = useState([]);
    const [columns, setColumns] = useState([
        { field: 'serialNo', headerName: 'S/NO', width: 100 },
        { field: 'enrolleeId', headerName: 'Enrollee ID', width: 200 },
        { field: 'lastName', headerName: 'Last Name', width: 150 },
        { field: 'firstName', headerName: 'First Name', width: 150 },
        { field: 'provider', headerName: 'Provider', width: 200 },
        { field: 'datePresent', headerName: 'Date Present', width: 200 },
      ]);



  useEffect(() => {
    const fetchData = async () => {
      showLoadingModal();
      try {
        const response = await axios.get(
          "https://metroflowbackend-872093905250.us-central1.run.app/medicalservices/all",
          {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json"
            },
          }
        );


        setActivity(response.data.result);
        setIsProcessed(true);
        hideLoadingModal();
      } catch (error) {
        hideLoadingModal();
        // Handle errors, e.g., show an error message
        console.error("Upload error:", error);
        Swal.fire({
          title: "Something went wrong!",
          text: error.message,
          icon: "error",
        });
      }
    };

    fetchData();
  }, []);
      const excelDateToJSDate = (serial) => {
        const utc_days = Math.floor(serial - 25569);
        const utc_value = utc_days * 86400; // 86400 seconds in a day
        const date_info = new Date(utc_value * 1000);
      
        const fractional_day = serial - Math.floor(serial) + 0.0000001;
      
        let total_seconds = Math.floor(86400 * fractional_day);
      
        const seconds = total_seconds % 60;
      
        total_seconds -= seconds;
      
        const hours = Math.floor(total_seconds / (60 * 60));
        const minutes = Math.floor(total_seconds / 60) % 60;
      
        return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours+1, minutes, seconds);
      };
      
      const handleFileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        
        reader.onload = (e) => {
          const binaryStr = e.target.result;
          const workbook = XLSX.read(binaryStr, { type: 'binary' });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 }); // Read as array of arrays
      
          console.log("Parsed Data (by index):", jsonData);
      
          // The first row in jsonData will be the header, and subsequent rows will be the data
          const headerRow = jsonData[0];  // First row (headers)
          const dataRows = jsonData.slice(1);  // Remaining rows (data)
      
          // Map each row using column indexes
          const formattedData = dataRows.map((row, index) => {
            const rawDate = row[6]; // The date string from the Excel file (column 6)
      
            let formattedDate;
      if (typeof rawDate === 'number') {
        formattedDate = excelDateToJSDate(rawDate).toISOString().slice(0, 19).replace('T', ' ');
      } else {
        formattedDate = rawDate; // If it's already a date string, keep it as is
      }

            return {
              id: index, 
              serialNo: row[0],        
              enrolleeId: row[1],      
              lastName: row[2],        
              firstName: row[4],       
              provider: row[5],        
              datePresent: formattedDate,  // Use the formatted date
            };
          });
      
          setFileData(formattedData);
        };
      
        reader.readAsBinaryString(file);
      };
      
  
    useEffect(() => {
      Prism.highlightAll(); // Assumes Prism.js for syntax highlighting
    }, []);
  
    const handleSendToBackend = async () => {

        if (!fileData.length) {
            alert("No file data to submit. Please upload and preview a file first.");
            return;
          }
        try {
          const response = await axios.post("https://metroflowbackend-872093905250.us-central1.run.app/medicalservices/upload", fileData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
                "Content-Type": "application/json"
            },
          });
          alert('Data successfully uploaded');
          console.log(response.data);
        } catch (error) {
          console.error('Error uploading data:', error);
          alert('Error uploading data. Please try again.');
        }
      };  


    return (
      <React.Fragment>
        <Sidebar />
        <Header onSkin={setSkin} />
        <div className="main main-app p-3 p-lg-4">
          <Container>
            <label className="main-title-label">Waiting Time Tracking System</label>
  
            <Tab.Container defaultActiveKey={"activityTab"} className="mt-5">
              <Nav className="nav-line">
                <Nav.Item>
                  <Nav.Link style={{ marginRight: "20px" }} eventKey="activityTab">
                    View Enrollee Activity
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link style={{ marginRight: "20px" }} eventKey="uploadTab">
                    Upload Tab
                  </Nav.Link>
                </Nav.Item>
              </Nav>
  
              <Tab.Content>
                <Tab.Pane eventKey="activityTab">
                <Card className="card-post mt-4">
      <Card.Header>
        <Card.Title>Enrollee Activity Log</Card.Title>
      </Card.Header>
      <Card.Body>
        {/* First Div: Context about the Waiting Time Tracking System */}
        <div>
          <p>
            The Waiting Time Tracking System is designed to help monitor the time
            enrollees spend waiting for services. 
          </p>
          <p>
            Please note that the units of measurement for all waiting times are in
            minutes, making it easier to compare and track the timeframes
            effectively.
          </p>
          <p>
            The current data reflects waiting times up to the latest update, which
            occurred today: <strong>{ new Date().toLocaleString()}</strong>.
          </p>
        </div>

        {/* Second Div: The EnrolleeActivityGrid */}
        <div>
          {isProcessed ? (
            <EnrolleeActivityGrid data={activity} />
          ) : (
            <p>No activity data available at the moment. Please upload or process the data.</p>
          )}
        </div>
      </Card.Body>
    </Card>
                </Tab.Pane>
  
                <Tab.Pane eventKey="uploadTab">
                  <Card className="card-post mt-4">
                    <Card.Header>
                      <Card.Title>Upload Hospital Presence Report</Card.Title>
                      <Link to="" className="link-more">
                        <i className="ri-more-2-fill"></i>
                      </Link>
                    </Card.Header>
                    <Card.Body>
                      <div className="mb-3">
                        <Form.Group controlId="formFile" className="mb-3">
                          <Form.Label>Upload file (Excel)</Form.Label>
                         
                            <input
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileUpload}
      />
                        </Form.Group>
  
                        {/* {!isLoading && (
                          <Button
                            style={{ marginRight: "20px" }}
                            variant="primary"
                            onClick={handleFileUpload}
                          >
                            Preview File
                          </Button>
                        )} */}
                        {isLoading && (
                          <Button style={{ marginRight: "20px" }} className="btn-sign">
                            Processing...
                            <span />
                            <Spinner size="sm" animation="border" />
                          </Button>
                        )}
  
                        <Button variant="secondary" onClick={handleSendToBackend}>
                          Submit File
                        </Button>
                      </div>
  
                      <br />
                      {fileData.length > 0 && (
        <div style={{ height: 400, width: '100%' }}>
          <DataGrid rows={fileData} columns={columns} pageSize={5} />
        </div>
      )}
                      {/* {isProcessed && <DataGrid data={fileData} />} */}
                    </Card.Body>
                  </Card>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </Container>
        </div>
      </React.Fragment>
    );
  }
